<template>
  <v-row dense>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        :disabled="!disabledFields"
        v-model="budget.validate_budget"
        label="Validade do orçamento *"
        placeholder="07 Dias"
        :rules="[(v) => !!v || 'Campo obrigatório']"
        rounded
        dense
        outlined
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-text-field
        :disabled="!disabledFields"
        v-model="budget.deadline"
        label="Prazo de Entrega *"
        placeholder="07 Dias"
        :rules="[(v) => !!v || 'Campo obrigatório']"
        rounded
        dense
        outlined
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-autocomplete
        :disabled="!disabledFields"
        v-model="budget.payment"
        label="Forma de pagamento *"
        :rules="[(v) => !!v || 'Campo obrigatório']"
        :items="paymentForms ? paymentForms : []"
        :item-value="'name'"
        :item-text="'name'"
        rounded
        dense
        outlined
      />
    </v-col>
    <v-col
      cols="12"
      md="6"
    >
      <v-select
        :disabled="!disabledFields"
        v-model="budget.shipping"
        label="Frete *"
        :rules="[(v) => !!v || 'Campo obrigatório']"
        :items="shippings ? shippings : []"
        :item-value="'name'"
        :item-text="'name'"
        rounded
        dense
        outlined
      />
    </v-col>
    <v-col
      cols="12"
      md="12"
    >
      <label class="subtitle-2 secondary--text">Descrição</label>
      <ckeditor
        :readOnly="!disabledFields"
        v-model="budget.description"
        label="Observações"
        rounded
        dense
        outlined
        auto-grow
      />
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    budget: {
      type: Object,
      required: true,
    },
    disabledFields: Boolean,
    paymentForms: Array,
    deadlines: Array,
    shippings: Array,
  },
}
</script>
